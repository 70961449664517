import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const getTokenEndPoint = process.env.REACT_APP_GET_TOKEN;

    const [error, setError] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        console.log(`${baseUrl}${getTokenEndPoint}?code=${code}`)
        if (code) {
            axios.post(`${baseUrl}${getTokenEndPoint}?code=${code}`)
                .then(response => {
                    console.log("API Response:", response);
                    if (response.status === 200 && response.data.status) {
                        localStorage.setItem("userDetails", JSON.stringify(response?.data));
                        navigate('/chat');
                    } else {
                        setError('Login failed: ' + (response.data.detail || 'Unknown error'));
                    }
                })
                .catch(error => {
                    console.error("API Error:", error);
                    setError(error.response ? error.response.data.detail : 'Login failed');
                });
        }
    }, [baseUrl, getTokenEndPoint, navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center">
            {error ? <p>{error}</p> : <p>Processing authentication...</p>}
        </div>
    );
};

export default AuthCallback;
