import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from "./Layout/Main";
import Login from "./Pages/Login/Login";
import NewPageChat from "./Pages/Chat/NewPageChat";
import AuthCallback from './Pages/AuthCallback/AuthCallback';
function App() {
  return (
    <div>
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route index element={<Login />} />
            <Route path="chat" element={<NewPageChat />} />
            <Route path="auth" element={<AuthCallback />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;

