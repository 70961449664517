import React from 'react';
import axios from 'axios';
import loginImg from "./../../Assets/loginImage.png"

const Login = () => {
    const handleLogin = () => {
        axios.get("https://tbgenapi.tbctestlab.com/auth/login")
        .then(res=>{
            if(res.status === 200){
                const redirectUrl = res.data?.auth_url
                if(redirectUrl){
                    window.location.href = redirectUrl
                }else{
                    console.log("redirect url not found")
                }
            }
        })
    };

    return (
        <div className="flex items-center justify-center">
            <div className="flex w-full h-full">
                <div className='p-2 w-1/2 lg:block hidden'>
                    <img src={loginImg} alt="Login" className='h-[calc(95vh-20px)] w-full object-cover rounded-md' />
                </div>
                <div className="w-full lg:w-1/2 flex flex-col justify-center p-14">
                    <h2 className="text-2xl font-semibold text-gray-700 text-center">Sign in to your account</h2>
                    <div className="mt-6 flex items-center justify-center">
                        <button className="flex items-center px-4 py-2 border rounded-md focus:outline-none" onClick={handleLogin}>
                            <img src="https://img.icons8.com/color/48/000000/microsoft.png" alt="Microsoft logo" className="w-6 h-6 mr-2" />
                            <span>Sign in with Microsoft</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
