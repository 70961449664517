import React from 'react'
import { Outlet } from 'react-router-dom'

const Main = () => {
  return (
    <div className='bg-gray-100 p-4 h-[100vh]'>
      <div className='max-w-[1440px] mx-auto bg-white rounded-lg'>
        <Outlet />
      </div>
    </div>
  )
}

export default Main