import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { ChatsListCard } from "./ChatsListCard";
import { IoArrowForwardSharp, IoSettingsOutline } from "react-icons/io5";
import { IoMdHelpCircleOutline, IoMdSearch } from "react-icons/io";
import { PiUploadBold } from "react-icons/pi";
import { TbMessages, TbSend } from "react-icons/tb";
import { LuHardDrive } from "react-icons/lu";
import { GrAttachment } from "react-icons/gr";
import ailogo from "../../Assets/aichatbot.png";
import axios from "axios";

const NewPageChat = () => {
  const userDetails = localStorage.getItem("userDetails");
  const [userName, setUserName] = useState(() => {
    return userDetails ? JSON.parse(userDetails).name : "";
  });
  const [question, setQuestion] = useState("");
  const [yourQuestion, setYourQuestion] = useState("");
  const [answer, setAnswer] = useState([]);
  const formatedDate = new Date().toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const authToken = userDetails ? JSON.parse(userDetails)?.access_token : null;

  console.log(question, authToken);

  const handleAIrequest = () => {
    setYourQuestion(question);
    setQuestion("");
    setAnswer("");
    axios
      .post(
        `https://tbgenapi.tbctestlab.com/rag-query/rag_query?token=${authToken}`,
        { question }
      )
      .then((res) => {
        setAnswer(res.data?.answer);
      });
  };
  return (
    <div className="flex h-[95vh] p-4 gap-4">
      <div className="w-1/4 flex flex-col">
        <button className="flex items-center border-2 px-4 py-2 rounded-lg justify-center border-[#256E7C] hover:border-[#2a91a5] font-bold gap-2 hover:bg-[#256E7C] hover:text-white">
          <FiPlus className="text-[22px]" />
          <p>New Chat</p>
        </button>
        <div className="my-4">
          <p className="font-bold">Recent Chats</p>
        </div>
        <div className="border-top border-bottom h-[60vh] overflow-y-scroll py-2 no-scrollbar">
          {/* <ChatsListCard title={"Text 001"} />
                    <ChatsListCard title={"Text 002"} />
                    <ChatsListCard title={"Text 003"} />
                    <ChatsListCard title={"Text 004"} />
                    <ChatsListCard title={"Text 005"} />
                    <ChatsListCard title={"Text 006"} />
                    <ChatsListCard title={"Text 007"} />
                    <ChatsListCard title={"Text 008"} />
                    <ChatsListCard title={"Text 009"} />
                    <ChatsListCard title={"Text 010"} />
                    <ChatsListCard title={"Text 011"} />
                    <ChatsListCard title={"Text 012"} />
                    <ChatsListCard title={"Text 013"} />
                    <ChatsListCard title={"Text 014"} />
                    <ChatsListCard title={"Text 015"} /> */}
        </div>
        <div className="mt-4 flex items-center gap-2 hover:bg-gray-100 py-2 px-3 rounded-lg">
          <IoSettingsOutline className="text-[20px]" />
          <p className="font-bold text-[18px]">Settings</p>
        </div>
        <div className="mt-2 flex items-center gap-2 hover:bg-gray-100 py-2 px-3 rounded-lg">
          <IoMdHelpCircleOutline className="text-[22px]" />
          <p className="font-bold text-[18px]">Help</p>
        </div>
      </div>
      <div className="w-3/4">
        <div className="flex justify-between">
          <div>
            <h3>Conversation Title Goes Here</h3>
            <p>Started {formatedDate}</p>
          </div>
          <div className="flex gap-3 items-center">
            <p className="text-[18px] font-medium">{userName}</p>
          </div>
        </div>
        <div className="h-[80vh] bg-blue-100 mt-4 rounded-lg relative flex flex-col justify-between">
          <div className="overflow-y-scroll h-[70vh] w-full relative">
            {yourQuestion === "" && (
              <div className="w-full mt-32">
                <div className="w-[90%] mx-auto relative">
                  <div className="bg-white rounded-lg p-5 flex flex-col items-center w-full">
                    <h2 className="font-bold">TBGenAI</h2>
                    <p>
                      I’m an AI powered mobility expert and I can answer any
                      question regarding your organization!
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 w-[90%] mx-auto mt-5">
                  <div className="bg-white p-3 rounded-lg flex items-center justify-between">
                    <p>I would like to know about the devices</p>
                    <IoArrowForwardSharp />
                  </div>
                  <div className="bg-white p-3 rounded-lg flex items-center justify-between">
                    <p>I would like to know about Users</p>
                    <IoArrowForwardSharp />
                  </div>
                  <div className="bg-white p-3 rounded-lg flex items-center justify-between">
                    <p>I would like to know about intune application</p>
                    <IoArrowForwardSharp />
                  </div>
                  <div className="bg-white p-3 rounded-lg flex items-center justify-between">
                    <p>I would like to know about tenable application</p>
                    <IoArrowForwardSharp />
                  </div>
                </div>
              </div>
            )}
            {yourQuestion && (
              <div className="absolute top-[30px] right-[40px] bg-blue-500 rounded-lg p-3 w-[300px]">
                <p className="text-[20px] text-white">{yourQuestion}</p>
              </div>
            )}
            {answer.length > 0 && (
              <div className="absolute" style={{ top: "70px", left: "40px" }}>
                <div className="bg-green-500 rounded-lg p-3 w-[300px]">
                  <p className="text-[20px] text-white">{answer}</p>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center px-3 gap-2 absolute bottom-[20px] w-full">
            <GrAttachment className="text-[22px]" />
            <div className="w-[95%] relative">
              <input
                value={question}
                type="text"
                placeholder="Type your message here or pick from the prompts"
                className="px-4 py-3 rounded-lg w-full"
                onChange={(e) => setQuestion(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleAIrequest();
                  }
                }}
              />
              <button
                className="bg-black p-2 rounded-lg absolute right-[10px] top-[15%]"
                onClick={handleAIrequest}
              >
                <TbSend className="text-white text-[20px]" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPageChat;
